import { NextRouter } from 'next/router';
import { backendApi, parser } from '@/services/backend';
import { PublicConfigResponse, WhitelistStatusResponse } from '@/services/backend/types';
import { ErrorMessage, Routes } from '@/types/types';

export async function doRedirectTo(router: NextRouter, path: string) {
  await router.push(path);
}

export async function doRedirectToLandingPage(router: NextRouter, anchor = '') {
  await router.push(Routes.landing_page + anchor);
}

export function doRedirectToCallbackUrl(router: NextRouter, route: Routes) {
  if (typeof window === 'undefined') {
    return;
  }
  const callbackUrl = new URLSearchParams(window.location.search).get('callbackUrl');

  if (callbackUrl) {
    void router.push(callbackUrl ? `${callbackUrl}` : route);
  }
}

export async function getServerSidePropsAppConfig(): Promise<PublicConfigResponse | ErrorMessage> {
  const config = backendApi.getConfig();
  return parser(PublicConfigResponse, config);
}

export async function getWhitelistStatus(): Promise<WhitelistStatusResponse | ErrorMessage> {
  const status = backendApi.getWhitelistStatus();
  return parser(WhitelistStatusResponse, status);
}
