import { AxiosError } from 'axios';
import { plainToInstance } from 'class-transformer';
import { ClassConstructor, ClassTransformOptions } from 'class-transformer/types/interfaces';
import { httpClient } from '@/lib/axios/httpClient';
import {
  MyNftsResponse,
  PublicConfigResponse,
  RequestMintResponse,
  WhitelistStatusResponse,
} from '@/services/backend/types';
import { ErrorMessage, SessionCustom } from '@/types/types';

export function parser<T, V>(
  cls: ClassConstructor<T>,
  plain: V,
  options?: ClassTransformOptions,
): T {
  return plainToInstance(
    cls,
    plain,
    options ?? {
      excludeExtraneousValues: true,
      exposeUnsetFields: false,
      strategy: 'excludeAll',
    },
  );
}

interface ApiError {
  statusCode: number;
  message: string;
  error: string;
}

class BackendApi {
  private readonly apiKey: string;
  private readonly apiEndpoint: string;

  public constructor() {
    this.apiKey = process.env.BACKEND_API_KEY;
    this.apiEndpoint = process.env.BACKEND_API_ENDPOINT;
  }

  private getHeaders() {
    return {
      'X-API-KEY': this.apiKey,
    };
  }

  public async getConfig(): Promise<PublicConfigResponse | ErrorMessage> {
    try {
      const { data } = await httpClient.get<PublicConfigResponse>(
        `${this.apiEndpoint}/v1/app/config`,
        {
          headers: this.getHeaders(),
        },
      );
      return parser(PublicConfigResponse, data);
    } catch (error: unknown) {
      const axiosError = error as AxiosError<ApiError>;
      return {
        code: axiosError.response?.data.statusCode,
        error: axiosError.response?.data.message,
      } as ErrorMessage;
    }
  }

  public async getMyNFTs(session: SessionCustom): Promise<MyNftsResponse | ErrorMessage> {
    try {
      const { data } = await httpClient.get(`${this.apiEndpoint}/v1/user/nfts`, {
        headers: this.getHeaders(),
        params: {
          walletAddress: session.walletAddress,
        },
      });

      return parser(MyNftsResponse, data);
    } catch (error: unknown) {
      const axiosError = error as AxiosError<ApiError>;
      return {
        code: axiosError.response?.data.statusCode,
        error: axiosError.response?.data.message,
      } as ErrorMessage;
    }
  }

  public async getWhitelistStatus(): Promise<WhitelistStatusResponse | ErrorMessage> {
    try {
      const { data } = await httpClient.get<WhitelistStatusResponse>(
        `${this.apiEndpoint}/v1/whitelist/status`,
        {
          headers: this.getHeaders(),
        },
      );
      return parser(WhitelistStatusResponse, data);
    } catch (error: unknown) {
      const axiosError = error as AxiosError<ApiError>;
      return {
        code: axiosError.response?.data.statusCode,
        error: axiosError.response?.data.message,
      } as ErrorMessage;
    }
  }

  public async requestMint(
    session: SessionCustom,
    code: string,
  ): Promise<RequestMintResponse | ErrorMessage> {
    try {
      const { data } = await httpClient.post(
        `${this.apiEndpoint}/v1/user/mint`,
        {
          walletAddress: session.walletAddress,
          code,
        },
        {
          headers: this.getHeaders(),
        },
      );

      return parser(RequestMintResponse, data);
    } catch (error: unknown) {
      const axiosError = error as AxiosError<ApiError>;

      return {
        code: axiosError.response?.data.statusCode,
        error: axiosError.response?.data.message,
      } as ErrorMessage;
    }
  }

  public async sendInvitations(
    session: SessionCustom,
    invitations: { name: string; email: string }[],
  ): Promise<void | ErrorMessage> {
    try {
      await httpClient.post(
        `${this.apiEndpoint}/v1/user/send/invitations`,
        {
          walletAddress: session.walletAddress,
          invitations,
        },
        {
          headers: this.getHeaders(),
        },
      );

      return undefined;
    } catch (error: unknown) {
      const axiosError = error as AxiosError<ApiError>;
      return {
        code: axiosError.response?.data.statusCode,
        error: axiosError.response?.data.message,
      } as ErrorMessage;
    }
  }
}

export const backendApi = new BackendApi();
