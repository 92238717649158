import { ReactNode } from 'react';

interface ButtonSecondaryProps {
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  desktopOnly?: boolean;
}

export const ButtonSecondary = (props: ButtonSecondaryProps) => {
  return (
    <button
      className={`
        bg-gradient-to-br from-primary-300/10 via-primary-600/10 to-secondary-600/10
        hover:bg-gradient-to-tl hover:from-primary-300/20 hover:via-primary-600/20 hover:to-secondary-600/20
        flex justify-center items-center gap-2
        border-t-primary-600/50 border-l-primary-600/50 border-r-secondary-600/50 border-b-secondary-600/50 border border-solid
        rounded-xl
        text-2xl
        h-[64px]
        px-5
        w-full
        w-[260px]
        ${props.disabled ? 'grayscale' : ''}
        ${props.desktopOnly ? 'hidden lg:flex' : ''}
      `}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};
