import { Expose, Type } from 'class-transformer';

export class RequestMintResponse {
  @Expose() public transactionId!: string;
}

export class PublicConfigResponse {
  @Expose() public saleOpenIn!: string;
  @Expose() public saleCloseIn!: string;
  @Expose() public saleOpen!: boolean;
  @Expose() public saleFinished!: boolean;
}

export class KeyValue {
  @Expose() public key!: string;
  @Expose() public value!: unknown;
  @Expose() public description?: string;
}

export class ImxToken {
  @Expose() public token_address!: string;
  @Expose() public token_id!: string;
  @Expose() public id!: string;
  @Expose() public user!: string;
  @Expose() public uri!: string;
  @Expose() public name!: string;
  @Expose() public description!: string;
  @Expose() public image_url!: string;
  @Expose() public animation_url?: string;
  @Expose() public metadata!: {
    Collection: string;
    Id: string;
    id: string;
    image_url: string;
    animation_url: string;
    animation_url_mime_type: string;
  };
  @Expose() public collection!: {
    name: string;
    icon_url: string;
  };
  @Expose() public created_at!: string;
  @Expose() public updated_at!: string;
  @Expose() public tokenType!: string;
  @Expose() @Type(() => KeyValue) public properties!: KeyValue[];
  @Expose() public contractAddress!: string;
}

export class WhitelistStatusResponse {
  @Expose() public status!: string;
}

export class MyNftsResponse {
  @Expose() @Type(() => ImxToken) public tokens!: ImxToken[];
  @Expose() public canSendInvitations!: boolean;
  @Expose() public saleFinished!: boolean;
  @Expose() public status!: string;
  @Expose() public name?: string;
  @Expose() public type?: 'primary' | 'secondary';
}

export class SendInvitationsResponse {
  @Expose() public name!: string;
  @Expose() public email!: string;
}
