import { LandingPageScreen } from '@/components/LandingPage/LandingPageScreen.component';
import { NextPageWithLayout } from '@/pages/_app';

export const LandingPage: NextPageWithLayout<{}> = () => {
  return (
    <>
      <LandingPageScreen />
    </>
  );
};

export default LandingPage;
