import BaseLayout from '@/components/BaseLayout/BaseLayout';
import { LoginFom } from '@/components/LandingPage/Login';
import { About } from './About';
import { Welcome } from './Welcome';

export const LandingPageScreen = () => {
  return (
    <BaseLayout
      currentStep="login"
      childrenHero={<Welcome />}
      childrenBox={<LoginFom />}
      childrenBottom={<About />}
    />
  );
};
