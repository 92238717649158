import { Signer } from '@ethersproject/abstract-signer';
import { BaseSigner, generateStarkWallet, getConfig, Workflows } from '@imtbl/core-sdk';
import { ConfigurableIframeOptions, Link, ProviderPreference } from '@imtbl/imx-sdk';
import { LinkResults } from '@imtbl/imx-sdk/dist/src/types';
import React from 'react';
import { httpClient } from '@/lib/axios/httpClient';
import { sleep } from '@/lib/utils';
import { _goerli, _mainnet, currentChain, LoginProvider } from '@/types/types';

const currentChainNameForImx = currentChain === _mainnet ? '' : '.sandbox';

export const apiUrlIMX = `https://api${currentChainNameForImx}.x.immutable.com/v1`;

export const linkIMX = (linkElementRef?: React.MutableRefObject<HTMLElement | null>) => {
  if (process.env.NEXT_PUBLIC_IMX_LINK_IFRAME === 'true' && linkElementRef?.current) {
    const linkIframeOptions: ConfigurableIframeOptions = {
      className: 'link-iframe',
      containerElement: linkElementRef.current,
    };
    return new Link(`https://link${currentChainNameForImx}.x.immutable.com`, linkIframeOptions);
  }
  return new Link(`https://link${currentChainNameForImx}.x.immutable.com`);
};

export const isWalletRegisteredInImx = async (walletAddress: string): Promise<boolean> => {
  const imxUserUrl = `${apiUrlIMX}/users/${walletAddress}`;
  try {
    console.log(`message=Check if Wallet is registered on IMX, url=[${imxUserUrl}]`);

    const imxResponse = await httpClient.get(imxUserUrl);
    console.log(
      `message=Wallet is registered on IMX, mintedTokensAccounts=[${imxResponse.data.accounts}]`,
    );
    return !!imxResponse.data.accounts;
  } catch (error) {
    console.error(`message=ERROR to fetch user on IMX, url=[${imxUserUrl}]`, error);
    return false;
  }
};

async function getL1Signer(
  signerRef: React.MutableRefObject<Signer | undefined | null>,
): Promise<Signer> {
  if (!signerRef.current) {
    await sleep(10);
    console.log('recursive getL1Signer');
    return getL1Signer(signerRef);
  }
  console.log('getL1Signer, found:', signerRef.current);
  return signerRef.current;
}

export function getConfigParams(chainId: number) {
  switch (chainId) {
    case _goerli.id:
      return {
        coreContractAddress: '0x7917eDb51ecD6CdB3F9854c3cc593F33de10c623',
        registrationContractAddress: '0x1C97Ada273C9A52253f463042f29117090Cd7D83',
        chainID: _goerli.id,
        basePath: 'https://api.sandbox.x.immutable.com',
      };
    case _mainnet.id:
      return {
        coreContractAddress: '0x5FDCCA53617f4d2b9134B29090C87D01058e27e9',
        registrationContractAddress: '0x72a06bf2a1CE5e39cBA06c0CAb824960B587d64c',
        chainID: _mainnet.id,
        basePath: 'https://api.x.immutable.com',
      };
    default:
      throw new Error('chainId not supported');
  }
}

export function getSdkWorkflows() {
  const coreSdkConfig = getConfig(getConfigParams(currentChain.id));
  return new Workflows(coreSdkConfig);
}

// doc: https://github.com/immutable/imx-core-sdk#workflows
export async function registerUserInIMX(
  loginProvider: LoginProvider,
  signerRef: React.MutableRefObject<Signer | undefined | null>,
  linkElementRef?: React.MutableRefObject<HTMLElement | null>,
): Promise<LinkResults.Setup> {
  try {
    switch (loginProvider) {
      case LoginProvider.MAGIC_LINK: {
        return await linkIMX(linkElementRef).setup({
          providerPreference: ProviderPreference.MAGIC_LINK,
        });
      }
      case LoginProvider.METAMASK:
      case LoginProvider.COINBASE: {
        const l1Signer = await getL1Signer(signerRef);
        if (await isWalletRegisteredInImx(await l1Signer.getAddress())) {
          console.log(
            `message=Wallet is already registered on IMX, walletAddress=${await l1Signer.getAddress()}`,
          );
          const result = {
            address: await l1Signer.getAddress(),
            starkPublicKey: 'not available',
            ethNetwork: currentChain.network,
            providerPreference: loginProvider,
          };
          console.log('imx.registerUser.success', result);
          return result;
        }

        // Sets up the L2Signer
        const l2Wallet = await generateStarkWallet(l1Signer);
        const l2Signer = new BaseSigner(l2Wallet.starkKeyPair);

        // Sets up the Core SDK workflows
        const coreSdkWorkflows = getSdkWorkflows();

        // Registers the user
        const walletConnection = { l1Signer, l2Signer };
        await coreSdkWorkflows.registerOffchain(walletConnection);
        const result = {
          address: await l1Signer.getAddress(),
          starkPublicKey: l2Wallet.starkPublicKey,
          ethNetwork: currentChain.network,
          providerPreference: loginProvider,
        };
        console.log('imx.registerUser.success', result);
        return result;
      }
      default: {
        throw new Error('LoginProvider not implemented yet');
      }
    }
  } catch (error) {
    console.log('imx.registerUserInIMX.error', error);
    throw error;
  }
}

export async function registerUserInIMXV2(
  loginProvider: LoginProvider,
  signerRef: React.MutableRefObject<Signer | undefined | null>,
  linkElementRef?: React.MutableRefObject<HTMLElement | null>,
): Promise<LinkResults.Setup> {
  try {
    return await linkIMX(linkElementRef).setup({
      providerPreference:
        LoginProvider.MAGIC_LINK === loginProvider
          ? ProviderPreference.MAGIC_LINK
          : ProviderPreference.METAMASK,
    });
  } catch (error) {
    console.log('imx.registerUserInIMXV2.error', error);
    throw error;
  }
}
