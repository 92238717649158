import * as React from 'react';
import { Info } from '@/components/common/Info';

export const Welcome = () => {
  return (
    <Info
      title="Merry Christmas"
      img={
        <div className="max-w-[410px] max-h-[410px]">
          <img src="/images/animated/christmas-tree.gif" alt="" />
        </div>
      }
    >
      <>
        <p>
          <strong>We are delighted to have you join us this Christmas.</strong>
        </p>
        <p>In 2023, Moonshot Originals will explore not just NFTs - but carbon offsets.</p>
        <p>
          The NFT below is a small taste of {"what's"} to come. Linked to an official Australian
          Carbon Credit Unit, it will offset your Christmas breakfast, lunch and dinner.
        </p>
      </>
    </Info>
  );
};
