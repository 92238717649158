import { LinkResults } from '@imtbl/imx-sdk';
import cx from 'classnames';
import { detect } from 'detect-browser';
import { utils } from 'ethers';
import { Spinner, Toast } from 'flowbite-react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { getCsrfToken, signIn } from 'next-auth/react';
import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { HiCheck, HiX } from 'react-icons/hi';
import { SiweMessage } from 'siwe';
import { Connector, useAccount, useConnect, useDisconnect, useSigner, useSignMessage } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { ButtonPrimary } from '@/components/Button/ButtonPrimary.component';
import { ButtonSecondary } from '@/components/Button/ButtonSecondary.component';
import { Card } from '@/components/common/Card';
import { useSessionCustom } from '@/lib/hooks/useSessionCustom';
import { doRedirectTo, doRedirectToCallbackUrl } from '@/lib/utils/ssrProps';
import IconLogin from '@/public/images/icon-login.svg';
import IconLoginMagicLink from '@/public/images/icon-login-magic-link.svg';
import IconMetamask from '@/public/images/logo-metamask-wallet.png';
import { linkIMX, registerUserInIMX, registerUserInIMXV2 } from '@/services/imx';
import { currentChain, LoginProvider, Routes } from '@/types/types';

export const LoginFom = () => {
  interface LoginProviderResult {
    message: SiweMessage;
    signature: string;
    email?: string;
    loginProvider: LoginProvider;
  }

  const [loginMagicLinkInProgress, setLoginMagicLinkInProgress] = useState(false);
  const [loginMetamaskInProgress, setLoginMetamaskInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | undefined>('');
  const [signInLoading, setSignInLoading] = useState(false);
  const [isLoginSuccess, setLoginSuccess] = useState(false);
  const [metaMaskConnector, setMetaMaskConnector] = useState<Connector>();

  const browser = detect();
  const router = useRouter();

  const { disconnect } = useDisconnect();
  const { connectors, connectAsync } = useConnect();
  const { signMessageAsync } = useSignMessage();
  const { data: l1Signer } = useSigner();
  const { address: account } = useAccount();
  const { session } = useSessionCustom();

  const signerRef = useRef(l1Signer);
  const linkElementRef = useRef(null);

  useEffect(() => {
    if (session?.walletAddress) {
      void doRedirectTo(router, Routes.mint);
    }
  }, [session, router]);

  useEffect(() => {
    signerRef.current = l1Signer;
  }, [l1Signer]);

  useEffect(() => {
    if (connectors) {
      const metaMaskConnectorFound = connectors.find(
        (connector) => connector instanceof MetaMaskConnector,
      );
      if (metaMaskConnectorFound) {
        setMetaMaskConnector(metaMaskConnectorFound);
      }
    }
  }, [connectors]);

  const handleLogin = async (
    event: MouseEvent,
    loginProvider: () => Promise<LoginProviderResult>,
  ) => {
    event?.preventDefault?.();
    setErrorMessage('');
    setSignInLoading(true);
    try {
      const loginProviderResponse = await loginProvider();
      const signInResponse = await signIn('credentials', {
        message: JSON.stringify(loginProviderResponse.message),
        signature: loginProviderResponse.signature,
        loginProvider: loginProviderResponse.loginProvider,
        redirect: false,
        ...(loginProviderResponse.email && { email: loginProviderResponse.email }),
      });
      if (signInResponse?.ok) {
        console.log('handleLogin.signIn.success', {
          loginProviderResponse,
          signInResponse,
        });

        setLoginSuccess(true);
        doRedirectToCallbackUrl(router, Routes.mint);
      } else {
        setErrorMessage(signInResponse?.error);
        console.log('handleLogin.signIn.error', {
          loginProviderResponse,
          signInResponse,
        });
      }
    } catch (error) {
      disconnect();
      console.error('handleLogin.signIn.error', { error });
    } finally {
      setSignInLoading(false);
    }
  };

  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  const handleLoginMetaMask = async (event: MouseEvent) => {
    setLoginMetamaskInProgress(true);
    const loginProvider = LoginProvider.METAMASK;
    await handleLogin(event, async () => {
      let response;
      try {
        response = await connectAsync({
          chainId: currentChain.id,
          connector: metaMaskConnector,
        });
        console.log('handleLoginMetaMask.handleLogin.success', { response });
      } catch (error: any) {
        if (error?.name === 'ConnectorNotFoundError') {
          setErrorMessage(
            <a href={getLinkDownloadMetaMask()} target="_blank" rel="noreferrer">
              Install MetaMask Wallet
            </a>,
          );
          console.error('MetaMask Connector not found', error.message);
          throw error;
        }

        // code -32002: MetaMask locked
        if (error?.code === -32002) {
          setErrorMessage('You need to unlock MetaMask');
          console.error('MetaMask locked', error.message);
          throw error;
        }
        console.error('handleLoginMetaMask.handleLogin.error', { error });

        if (error?.name !== 'ConnectorAlreadyConnectedError') {
          throw error;
        }
      }

      await registerUserInIMX(loginProvider, signerRef);

      const message = await buildSiweMessage(response?.account ?? account);
      const signature = await signMessageAsync({ message: message.prepareMessage() });

      return {
        message,
        signature,
        loginProvider,
      };
    });
    setLoginMetamaskInProgress(false);
  };

  const handleLoginMagicLink = async (event: MouseEvent) => {
    setLoginMagicLinkInProgress(true);
    await handleLogin(event, async () => {
      let response: LinkResults.Setup;
      try {
        response = await registerUserInIMXV2(LoginProvider.MAGIC_LINK, signerRef, linkElementRef);
        console.log('handleLoginMagicLink.handleLogin.success', { response });
      } catch (error: any) {
        console.error('handleLoginMagicLink.handleLogin.error', { error });
        throw error;
      }

      const message = await buildSiweMessage(response.address);
      const signature = await linkIMX(linkElementRef).sign({
        message: message.prepareMessage(),
        description: message.prepareMessage(),
      });

      return {
        message,
        email: response.email,
        signature: signature.result,
        loginProvider: LoginProvider.MAGIC_LINK,
      };
    });
    setLoginMagicLinkInProgress(false);
  };

  const handleLoginMetaMaskWithIMXLink = async (event: MouseEvent) => {
    setLoginMetamaskInProgress(true);
    await handleLogin(event, async () => {
      let response: LinkResults.Setup;
      try {
        response = await registerUserInIMXV2(LoginProvider.METAMASK, signerRef, linkElementRef);
        console.log('handleLoginMetaMaskWithIMXLink.handleLogin.success', { response });
      } catch (error: any) {
        console.error('handleLoginMetaMaskWithIMXLink.handleLogin.error', { error });
        throw error;
      }

      const message = await buildSiweMessage(response.address);
      const signature = await linkIMX(linkElementRef).sign({
        message: message.prepareMessage(),
        description: message.prepareMessage(),
      });

      return {
        message,
        email: response.email,
        signature: signature.result,
        loginProvider: LoginProvider.METAMASK,
      };
    });
    setLoginMetamaskInProgress(false);
  };

  const handleLoginProvider = async (event: MouseEvent, provider: LoginProvider) => {
    setErrorMessage('');
    try {
      switch (provider) {
        case LoginProvider.MAGIC_LINK:
          return await handleLoginMagicLink(event);
        case LoginProvider.METAMASK:
          return await handleLoginMetaMaskWithIMXLink(event);
      }
    } catch (e) {
      console.log('generic error', { e });
    }
    throw new Error(`handleLoginProvider: '${provider}' Not implemented`);
  };

  const getLinkDownloadMetaMask = () => {
    let linkDownload = 'https://metamask.io/download/';
    switch (browser?.os) {
      case 'Android OS': {
        linkDownload = 'https://metamask.app.link/bxwkE8oF99';
        break;
      }
      case 'Mac OS': {
        linkDownload = 'https://metamask.app.link/skAH3BaF99';
        if (browser.type === 'browser' && browser.name === 'chrome') {
          linkDownload =
            'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn';
        }
        break;
      }
      case 'iOS': {
        linkDownload = 'https://metamask.app.link/skAH3BaF99';
        break;
      }
    }
    return linkDownload;
  };

  const buildSiweMessage = async (address = ''): Promise<SiweMessage> => {
    return new SiweMessage({
      domain: window.location.host,
      address: utils.getAddress(address),
      statement: 'Sign in with Ethereum to the app.',
      uri: window.location.origin,
      version: '1',
      chainId: currentChain.id,
      nonce: await getCsrfToken(),
    });
  };

  return (
    <Card
      icon={<IconLogin />}
      title="Login"
      text={
        <>
          To claim your NFT log in using our Email Magic Link
          <span className={'hidden lg:inline'}> or connect your MetaMask wallet</span>
        </>
      }
    >
      {isLoginSuccess && (
        <Toast>
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
            <HiCheck className="h-5 w-5" />
          </div>
          <div className="ml-3 text-sm font-normal">Login Success.</div>
        </Toast>
      )}
      {errorMessage && (
        <Toast>
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
            <HiX className="h-5 w-5" />
          </div>
          <div className="ml-3 text-sm font-normal">
            <span>There is some problem when you log in.</span> {errorMessage}
          </div>
        </Toast>
      )}
      <div className="flex flex-col 2md:flex-row gap-8">
        <ButtonPrimary
          onClick={async (event) => handleLoginProvider(event, LoginProvider.MAGIC_LINK)}
          disabled={signInLoading}
          className={'w-[260px]'}
        >
          <div className="w-[33px] h-[33px]">
            {loginMagicLinkInProgress ? <Spinner /> : <IconLoginMagicLink />}
          </div>
          <span className="text-sm text-white leading-4">
            Connect with <strong>Email</strong>
          </span>
        </ButtonPrimary>
        <ButtonSecondary
          onClick={async (event) => handleLoginProvider(event, LoginProvider.METAMASK)}
          disabled={signInLoading}
          desktopOnly={true}
        >
          <div className="w-[33px] h-[33px]">
            {loginMetamaskInProgress ? <Spinner /> : <Image src={IconMetamask} alt="" />}
          </div>
          <span className="text-sm text-text-300 leading-4">
            Connect with <strong>MetaMask</strong>
          </span>
        </ButtonSecondary>
      </div>
      {process.env.NEXT_PUBLIC_IMX_LINK_IFRAME === 'true' && (
        <>
          {(loginMagicLinkInProgress || loginMetamaskInProgress) && <div className="imx-overlay" />}
          <div
            className={`${cx({
              ['imx-sidebar']: !(loginMagicLinkInProgress || loginMetamaskInProgress),
              ['imx-sidebar-active']: loginMagicLinkInProgress || loginMetamaskInProgress,
            })}`}
          >
            <div className="LinkSideBar__elementRef" ref={linkElementRef}></div>
          </div>
        </>
      )}
    </Card>
  );
};
