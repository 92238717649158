import * as React from 'react';
import { Info } from '@/components/common/Info';
import IconArrow from '@/public/images/icon-arrow-right.svg';

export const About = () => {
  return (
    <Info title="About Moonshot" img={<img src="/images/logo-middle.png" alt="" />} reverse>
      <>
        <p>
          Moonshot is an Australian Web3 innovation lab delivering creatively diverse and
          technically progressive NFT experiences. We support brands that create unique, impactful
          and relevant NFT-led campaigns and launch our own independent projects (like our carbon
          offset project early next year).
        </p>
        <p>To learn more about Moonshot visit our website:</p>
        <a
          href="https://moonshotgaragelabs.com"
          className="flex gap-2 items-center text-transparent bg-gradient-to-br from-primary-300 via-primary-600 to-secondary-600 bg-clip-text"
          target="_blank"
          rel="noreferrer"
        >
          moonshotgaragelabs.com
          <IconArrow width={12} height={10.5} />
        </a>
      </>
    </Info>
  );
};
