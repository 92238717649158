import { ButtonHTMLAttributes } from 'react';

type ButtonPrimaryProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonPrimary = ({ children, disabled, className, ...props }: ButtonPrimaryProps) => {
  return (
    <button
      disabled={disabled}
      className={`
        bg-gradient-to-br from-primary-300/90 via-primary-600/90 to-secondary-600/90
        hover:bg-gradient-to-tl hover:from-primary-300 hover:via-primary-600 hover:to-secondary-600
        flex justify-center items-center gap-2 shrink-0 
        text-white 
        rounded-xl
        text-2xl
        h-[64px]
        px-5
        w-full
        ${disabled ? 'grayscale' : ''}
        ${className}
        
      `}
      {...props}
    >
      {children}
    </button>
  );
};

export const ButtonPrimaryOutlined = ({
  children,
  disabled,
  className,
  ...props
}: ButtonPrimaryProps) => {
  return (
    <button
      className={`
        hover:bg-gray-100
        flex justify-center items-center gap-2 shrink-0 
        text-white 
        rounded-xl
        text-2xl
        h-[46px]
        px-5
        w-full
        ${disabled ? 'grayscale' : ''}
        ${className}
        
      `}
      style={{
        border: '1px solid #B8AFAF',
      }}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
